import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import moment from 'moment-timezone';

import routesMap from '../../Routes';
import withOrderContext from '../../withOrderContext';
import { pushToLayer } from '../../services/googleTagManager';
import momentFr from '../../config/moment.fr';
import {
  colors,
  mobileThresholdPixels,
  StyledTitle2 as Title2,
} from '../../components/home/v3/styledComponents';
import LogoButton from './LogoButton';
import Calendar from './Calendar';
import SlotSelector from './SlotSelector';
import SlotsSummary from './SlotsSummary';
import calendar from '../../assets/calendar.svg';
import calendarChecked from '../../assets/calendarChecked.svg';
import clock from '../../assets/clock.svg';
import {
  dateFormat,
  getActiveSlots,
  getBeginHour,
} from '../../services/slotsFormatting';
import Feedback from '../../components/home/v3/Feedback';
import photoLaura from '../../assets/feedbacks/laura.png';
import OrderContext from '../../context/OrderContext';

const feedback = {
  photo: photoLaura,
  name: 'Laura',
  jobDescription: 'a fait retoucher ses robes par Ondine',
  // eslint-disable-next-line max-len
  feedback:
    "A l'heure, a bien compris ma commande ! Disponibilité des couturiers et la rapidité et le fait que ce soit à la maison en plus un dimanche enfin parfait quoi !",
  rating: 5,
};

moment.updateLocale('fr', momentFr);
moment.tz.setDefault('Europe/Paris');

function createHourSlot(hours, is30 = false) {
  return {
    hours,
    minutes: is30 ? 30 : 0,
    string1: is30 ? `${hours}h30` : `${hours}h`,
    hoursEnd: is30 ? hours + 1 : hours,
    minutesEnd: is30 ? 0 : 30,
    string2: is30 ? `${hours + 1}h` : `${hours}h30`,
    isActive: false,
  };
}

function createAllHourSlot(beginHour, endHour, removeLastOne = true) {
  const slots = [];
  for (let slotHour = beginHour; slotHour <= endHour; slotHour += 1) {
    slots.push(createHourSlot(slotHour));
    slots.push(createHourSlot(slotHour, true));
  }
  if (removeLastOne) slots.pop();
  return slots;
}

const P = styled.p`
  font-family: Roboto;
  font-weight: normal;
  line-height: 28px;
  font-size: 18px;
  text-align: center;
  color: ${colors.navy};
  margin: 40px 112px 69px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 20px 0px 40px;
    line-height: 17px;
    font-size: 14px;
    padding: 0px 30px;
  }
`;

const Message = styled.p`
  background: ${colors.navy};
  border-radius: 2px;
  box-shadow: 0px 2px 6px ${colors.shadow};
  color: ${colors.white};
  font-size: 14px;
  line-height: 20px;
  padding: 15px 20px;
  text-align: center;
  width: 427px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 12px;
    margin: 15px 0 0 0;
    width: 75vw;
  }
`;

const ComponentTitle = styled.p`
  font-family: Libre Baskerville;
  line-height: 28px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin: ${props =>
    props.noMarginTopAndBottom ? '0px 0px 0px 8px' : '30px 0px 30px 8px'};
  color: ${colors.navy};
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0 0 5px 8px;
  }
`;

const ComponentsContainer = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent || 'space-between'};
  width: 100%;
  margin-bottom: ${props => (props.largeMarginBottom ? '60' : '23')}px;
  ${props => props.noMarginBottom && 'margin-bottom: 0px;'}
  ${props => props.marginTop && 'margin-top: 100px;'}
  ${props => props.column && 'flex-direction: column; align-items: flex-start;'}
  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: ${props =>
    props.mobileReverse ? 'column-reverse' : 'column'};
    align-items: center;
    ${props => props.marginTop && 'margin-top: 60px;'}
    ${props => !props.noMarginBottom && 'margin-bottom: 20px;'}
  }
`;

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props =>
    props.borderBottom && `border-bottom: 0.75px solid ${colors.lightGrey2}; margin-bottom: 20px;`}
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 40px;
  }
`;

const ComponentHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

const StyledLink = styled.div`
  text-decoration: none;
  @media (max-width: ${mobileThresholdPixels}) {
    order: -1;
  }
`;

const Availabilities = () => {
  const { slots, setSlots } = useContext(OrderContext);
  const [date, setDate] = useState(
    getBeginHour(moment()) > 21 ? moment().add(1, 'day') : moment(),
  );
  const [selectedDates, setSelectedDates] = useState(slots || {});
  const [isSlotClickedTracked, setIsSlotClickedTracked] = useState(false);
  const [allDay, setAllDay] = useState({
    isActive: false,
    text: 'Toute la journée',
  });
  const [slotBlocks, setSlotBlocks] = useState([
    {
      isActive: false,
      text: 'Toute la matinée',
      slots: createAllHourSlot(7, 12, false),
    },
    {
      isActive: false,
      text: "Tout l'après-midi",
      slots: createAllHourSlot(13, 17, false),
    },
    {
      isActive: false,
      text: 'Toute la soirée',
      slots: createAllHourSlot(18, 21),
    },
  ]);

  const trackSlotClick = () => {
    if (!isSlotClickedTracked) {
      setIsSlotClickedTracked(true);
      pushToLayer({
        event: 'Order Funnel - Time Slot Chosen',
      });
    }
  };

  const selectDateAndSlot = () => {
    trackSlotClick();
    const newSelectedDates = { ...selectedDates };
    newSelectedDates[date.format(dateFormat)] = {
      date: moment(date),
      allDay: { ...allDay },
      slotBlocks: [...slotBlocks],
    };
    setAllDay(allDay);
    setSlotBlocks(slotBlocks);
    setSelectedDates({ ...newSelectedDates });
  };

  useEffect(() => {
    selectDateAndSlot();
  }, [slotBlocks]);

  const beginHour = getBeginHour(date);
  const activeSlots = getActiveSlots(selectedDates);

  const isAllActive = period =>
    period.reduce((acc, elem) => (acc ? elem.isActive : false), true);

  const toogleSlot = (indexPartDay, indexSlot, isPartDayActive = false) => {
    const newSlotBlocks = [...slotBlocks];
    if (isPartDayActive) {
      newSlotBlocks[indexPartDay].slots[indexSlot].isActive = isPartDayActive;
    } else {
      newSlotBlocks[indexPartDay].slots[indexSlot].isActive = !newSlotBlocks[
        indexPartDay
      ].slots[indexSlot].isActive;
      newSlotBlocks[indexPartDay].isActive = isAllActive(
        newSlotBlocks[indexPartDay].slots,
      );
      allDay.isActive = isAllActive(newSlotBlocks);
    }
    setSlotBlocks(newSlotBlocks);
  };

  const tooglePartDay = (indexPartDay, isAllDayActive = false) => {
    const newSlotBlocks = [...slotBlocks];
    if (isAllDayActive) {
      newSlotBlocks[indexPartDay].isActive = isAllDayActive;
      newSlotBlocks[indexPartDay].slots.map((slot, index) =>
        toogleSlot(indexPartDay, index, newSlotBlocks[indexPartDay].isActive),
      );
    } else {
      newSlotBlocks[indexPartDay].isActive = !newSlotBlocks[indexPartDay]
        .isActive;
      newSlotBlocks[indexPartDay].slots.map((slot, index) =>
        toogleSlot(indexPartDay, index, newSlotBlocks[indexPartDay].isActive),
      );
      allDay.isActive = isAllActive(newSlotBlocks);
    }
  };

  const toogleAllDay = () => {
    const newAllDay = { ...allDay };
    const newSlotBlocks = [...slotBlocks];
    newAllDay.isActive = !newAllDay.isActive;
    newSlotBlocks.map((slot, index) =>
      tooglePartDay(index, newAllDay.isActive),
    );
    setSlotBlocks(newSlotBlocks);
    setAllDay(newAllDay);
  };

  const updateSlots = (selectedDate) => {
    const newSlotBlocks = selectedDates[selectedDate.format(dateFormat)]
      ? [...selectedDates[selectedDate.format(dateFormat)].slotBlocks]
      : [
        {
          isActive: false,
          text: 'Toute la matinée',
          slots: createAllHourSlot(7, 12, false),
        },
        {
          isActive: false,
          text: "Tout l'après-midi",
          slots: createAllHourSlot(13, 17, false),
        },
        {
          isActive: false,
          text: 'Toute la soirée',
          slots: createAllHourSlot(18, 21),
        },
      ];
    const newAllDay = selectedDates[selectedDate.format(dateFormat)]
      ? {
        ...selectedDates[selectedDate.format(dateFormat)].allDay,
      }
      : {
        isActive: false,
        text: 'Toute la journée',
      };
    setAllDay({ ...newAllDay });
    setSlotBlocks([...newSlotBlocks]);
    setDate(moment(selectedDate));
  };

  const goToOrderSummary = () => {
    setSlots(selectedDates);
    navigate(routesMap.Step1Summary.url);
  };

  const goNext = () => {
    setSlots(selectedDates);
    navigate(routesMap.Step3.url);
  };

  return (
    <div>
      <Title2>Quand êtes-vous disponible ?</Title2>
      <P>
        Pendant votre RDV à domicile, le couturier prend vos mesures, épingle et
        valide avec vous vos attentes. Il repart ensuite avec le vêtement dans
        son atelier.
      </P>
      <ComponentsContainer noMarginBottom>
        <ComponentContainer borderBottom>
          <ComponentHeader>
            <img src={calendar} alt="Sélectionnez plusieurs dates" />
            <ComponentTitle>Sélectionnez plusieurs dates</ComponentTitle>
          </ComponentHeader>
          <Calendar
            selectedDate={date}
            selectedDates={selectedDates}
            updateSlots={updateSlots}
          />
        </ComponentContainer>

        <ComponentContainer>
          <ComponentHeader>
            <img src={clock} alt="Sélectionnez plusieurs créneaux" />
            <ComponentTitle>Et plusieurs créneaux</ComponentTitle>
          </ComponentHeader>
          <SlotSelector
            activeSlots={activeSlots}
            allDay={allDay}
            slotBlocks={slotBlocks}
            beginHour={beginHour}
            toogleAllDay={toogleAllDay}
            tooglePartDay={tooglePartDay}
            toogleSlot={toogleSlot}
          />
          {activeSlots.length >= 1 && (
            <Message>
              On vous conseille de sélectionner plusieurs créneaux pour garantir
              un rendez-vous dans les meilleurs délais ;)
            </Message>
          )}
        </ComponentContainer>
      </ComponentsContainer>

      <ComponentsContainer justifyContent="flex-start" column largeMarginBottom>
        <ComponentHeader>
          <img src={calendarChecked} alt="Créneaux sélectionnés" />
          <ComponentTitle noMarginTopAndBottom>
            Créneaux sélectionnés
          </ComponentTitle>
        </ComponentHeader>
        <ComponentContainer>
          {selectedDates && Object.keys(selectedDates).length > 0 && (
            <SlotsSummary
              activeSlots={activeSlots}
              selectedDates={selectedDates}
            />
          )}
        </ComponentContainer>
      </ComponentsContainer>

      <ComponentsContainer mobileReverse>
        <StyledLink onClick={goToOrderSummary}>
          <LogoButton back noZoom>
            Modifier la commande
          </LogoButton>
        </StyledLink>

        <StyledLink onClick={goNext}>
          <LogoButton isAvailable={!!activeSlots.length}>
            {"Dernière étape, l'adresse"}
          </LogoButton>
        </StyledLink>
      </ComponentsContainer>

      <ComponentsContainer marginTop noMarginBottom>
        <Feedback isTransparent feedback={feedback} />
      </ComponentsContainer>
    </div>
  );
};

Availabilities.propTypes = {
  orderContext: PropTypes.shape({
    setSlots: PropTypes.func.isRequired,
    slots: PropTypes.shape({}),
  }).isRequired,
};

export default withOrderContext(Availabilities);
