import React from 'react';

import Layout from '../../layouts/index';
import { Container, Content, StepContainer } from '../../components/home/v3/styledComponents';
import MenuBar from '../../components/home/MenuBar';
import Footer from '../../components/home/v3/Footer';
import Availabilities from '../../components/order/Availabilities';
import ProgressBar from '../../components/order/ProgressBar';
import BenefitsBanner from '../../components/home/v3/BenefitsBanner';
import PlatformLogo from '../../components/PlatformLogo';

const Step2 = () => (
  <Layout routeSlug="Step2">
    <Container hasNoBanner>
      <MenuBar showGiftBanner={false} />
      <PlatformLogo />
      <Content>
        <StepContainer smallMarginBottom>
          <ProgressBar step={2} />
          <Availabilities />
        </StepContainer>
      </Content>
      <BenefitsBanner backgroundColor="linen" color="navy" />
      <Footer />
    </Container>
  </Layout>
);

export default Step2;
