import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors, mobileThresholdPixels } from '../home/v3/styledComponents';

const MainContainer = styled.div`
  background-color: ${colors.white};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  width: 427px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0;
    width: 75vw;
  }
`;

const Slots = styled.div`
  display: flex;
  flex-direction: column;
  width: 203px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const Container = styled.div`
  align-items: center;
  border-radius: 3px;
  color: ${props => (props.isActive ? colors.white : colors.navy)};
  display: flex;
  cursor: pointer;
  height: ${props => (props.small ? '1' : '34')}px;
  justify-content: center;
  margin-top: ${props => (props.small ? '4' : '0')}px;
  transition: all 0.5s ease;
  width: ${props => (props.full ? '100%' : '95%')};
  ${props =>
    props.isActive
      ? `background-color: ${colors.navy};`
      : props.linen && `background-color: ${colors.linen};`}
  ${props => !props.isAvailable && 'opacity: 0.4; pointer-events: none;'}
  ${props => props.marginTop && 'margin-top: 16px;'}
  ${props => props.marginBottom && 'margin-bottom: 3px;'}
`;

const SubContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Title = styled.p`
  font-family: Libre Baskerville;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 13px;
  line-height: 24px;
  margin: 0;
`;

const Hours = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const SlotContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 35px;
  margin: 2px 0;
  width: 45%;
`;

const Line = styled.div`
  background-color: ${colors.blueGrey};
  flex: 1;
  height: 1px;
  position: relative;
  width: 100%;
`;

const SlotSelector = ({
  allDay,
  slotBlocks,
  toogleAllDay,
  tooglePartDay,
  toogleSlot,
  beginHour,
}) => (
  <MainContainer>
    <Container
      full
      linen
      onClick={toogleAllDay}
      isActive={allDay.isActive}
      isAvailable={beginHour === slotBlocks[0].slots[0].hours}
    >
      <Title>{allDay.text}</Title>
    </Container>
    <SubContainer>
      {slotBlocks &&
        slotBlocks.map((partDaySlots, indexPartDay) => (
          <Slots key={partDaySlots.text}>
            <Container
              full
              linen
              marginTop
              marginBottom
              onClick={() => tooglePartDay(indexPartDay)}
              isActive={partDaySlots.isActive}
              isAvailable={beginHour <= partDaySlots.slots[0].hours}
            >
              <Title>{partDaySlots.text}</Title>
            </Container>
            <Hours>
              {partDaySlots.slots.map((slot, indexSlot) => (
                <SlotContainer key={slot.string1}>
                  <Container
                    onClick={() => toogleSlot(indexPartDay, indexSlot)}
                    isActive={slot.isActive}
                    isAvailable={beginHour <= slot.hours}
                  >
                    <Text>
                      {slot.string1} - {slot.string2}
                    </Text>
                  </Container>
                  {indexSlot !== partDaySlots.slots.length - 1 &&
                    (partDaySlots.slots.length % 2 !== 0 || indexSlot !== partDaySlots.slots.length - 2) && (
                    <Container small full><Line /></Container>
                  )}
                </SlotContainer>
              ))}
            </Hours>
          </Slots>
        ))}
    </SubContainer>
  </MainContainer>
);

SlotSelector.propTypes = {
  allDay: PropTypes.shape({}).isRequired,
  slotBlocks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toogleAllDay: PropTypes.func.isRequired,
  tooglePartDay: PropTypes.func.isRequired,
  toogleSlot: PropTypes.func.isRequired,
  beginHour: PropTypes.number.isRequired,
};

export default SlotSelector;
